import { assertIsDefined } from "./assertions";

/**
 * Fischer-Yates shuffle
 * https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm
 */
export function shuffle<T>(input: ReadonlyArray<T>): Array<T> {
  const copy = [...input];
  for (let i = copy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const first = copy[j];
    const second = copy[i];
    assertIsDefined(first);
    assertIsDefined(second);
    copy[i] = first;
    copy[j] = second;
  }
  return copy;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapBy<T extends Record<K, any>, K extends keyof T>(
  input: ReadonlyArray<T>,
  key: K,
): Record<T[K], T> {
  return input.reduce(
    (prev, curr) => {
      const keyValue = curr[key];
      prev[keyValue] = curr;
      return prev;
    },
    {} as Record<T[K], T>,
  );
}

type ExtractKeysOfValueType<T, K> = {
  [I in keyof T]: T[I] extends K ? I : never;
}[keyof T];

export const groupBy = function <T>(
  items: T[] | readonly T[],
  key: ExtractKeysOfValueType<T, string | number>,
): Record<string | number, Array<T>> {
  return (items as T[]).reduce(
    function (groups, item) {
      // I don't understand why this type doens't work
      const groupKey = item[key] as unknown as string | number;
      const group: Array<T> = groups[groupKey] ?? [];
      group.push(item);
      groups[groupKey] = group;
      return groups;
    },
    {} as Record<string | number, Array<T>>,
  );
};

type ExtractKeyFn<T> = (value: T) => string;
interface Grouped<T> {
  key: string;
  group: T[];
}

/**
 * Groups the elements of the array by keys produced by @param keyFn, but only
 * groups elements that are adjacent in @param items
 */
export function groupByAdjacent<T>(
  items: T[] | readonly T[],
  keyFn: ExtractKeyFn<T>,
): Grouped<T>[] {
  if (items.length === 0) return [];
  // checked by first line of fn
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const first = items[0]!;

  const groups: Grouped<T>[] = [
    {
      key: keyFn(first),
      group: [first],
    },
  ];
  for (let i = 1; i < items.length; i++) {
    // groups initialized with 1 el
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentGroup = groups[groups.length - 1]!;

    // checked by loop condition
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const iterItem = items[i]!;
    const iterKey = keyFn(iterItem);
    if (iterKey === currentGroup.key) {
      currentGroup.group.push(iterItem);
    } else {
      groups.push({
        key: iterKey,
        group: [iterItem],
      });
    }
  }
  return groups;
}

/**
 * Inserts `separator` between every element of `items`.
 * For example, if provided `intersperse([1, 2, 3], 5)`
 * returns `[1, 5, 2, 5, 3]`.
 */
export const intersperse = <T>(items: T[], separator: T): readonly T[] =>
  items.flatMap((item) => [separator, item]).slice(1);

/** Same as `intersperse` but accepts a function to produce each separator */
export const intersperseWith = <T>(
  items: T[],
  separatorFunc: (item: T, index: number) => T,
): readonly T[] =>
  items.flatMap((item, i) => [separatorFunc(item, i), item]).slice(1);

export function arrayify<T>(arg: T | Array<T>): Array<T> {
  if (Array.isArray(arg)) {
    return arg;
  } else {
    return [arg];
  }
}
